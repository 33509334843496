<template>
  <div class="signDetail">
    <p><van-icon name="warning-o" />请认真准确填写以下申请信息</p>
    <div class="signDetailCon">
      <ul>
        <li>
          <span>姓名/企业名</span>
          <span>{{detailData.userName}}</span>
        </li>
        <li>  
          <span>证件名称</span>
          <span>{{detailData.cardTypeName}}</span>
        </li>
        <li>  
          <span>证件号码</span>
          <span>{{detailData.cardNo}}</span>
        </li>
        <!-- <li>  
          <span>联系人</span>
          <span><input v-model="detailData.contractorName" placeholder="请输入联系人" ></span>
        </li> -->
        <li>  
          <span>联系电话</span>
          <span>{{detailData.userPhone}}</span>
        </li>
        <li>  
          <span>电子邮箱<van-icon name="warning-o" @click="tips()" /></span>
          <span>
            <input type="text" v-model="detailData.email" onkeyup="this.value=this.value.replace(/\s+/g,'')" placeholder="请输入电子邮箱" class="inputArea">
            <van-icon name="close" @click="close('email')" />
          </span>
        </li>
        <li>
          <span>认购金额</span>
          <span>{{detailData.reserveAmount}}万元</span>
        </li>
      </ul>
    </div>
    <div class="account">
      <p><van-icon name="warning-o" />认购人参与认购本产品的划出账户与接受分配本息的划入账户，必须为以认购人名义开立的同一个账户</p>
      <p>收付款账户：请仔细核对账户信息，如使用其他银行账户，可编辑修改</p>
      <ul>
        <li>
          <span>姓名/企业名</span>
          <span>{{bankInfo.name}}</span>
        </li>
        <li>
          <span>开户银行</span>
          <span><input v-model="bankInfo.accountBankName" placeholder="请输入开户行" ></span>
          <span class="selectBank" @click="selectBankCardList()">选择</span>
        </li>
         <li>
          <span>银行账户</span>
          <span><input v-model="bankInfo.bankCard" placeholder="请输入银行账户" ></span>
        </li>
      </ul>
    </div>
    <div class="book">
      <ul> 
         <li @click="getProtocolPreview(1)">
          <span>《认购协议》</span>
          <span><van-icon name="arrow" /></span>
        </li>
        <li @click="getProtocolPreview(9)" data-type="9">
          <span>《产品说明书》</span>
          <span><van-icon name="arrow" /></span>
        </li>
      </ul>
    </div>
    <div class="xieyi">
      <input type="checkbox" id="xieyi" @click="checkXieyi" v-model="checked">
      <b>我已阅读并同意</b> <span>《认购协议》</span>
    </div>
    <div class="button">
      <span @click=doSignFun()>立即签约</span>
    </div>
    <p class="tips">风险提示：理财有风险，投资需谨慎</p>

     <!-- 选择银行卡弹窗 -->
    <div class="mask" v-if="isMask">
        <div class="contents">
            <div class="title"><span>选择银行卡</span><span class="cancel" @click="closeBank()">取消</span></div>
            <div class="detail">
                <ul>
                  <li v-for="item in ListData" @click="selectB(item)">
                    <p class="accountBankName" :data-name="item.account">
                      {{item.name}}
                    </p>
                    <p class="account">
                     {{ item.bankCard}}
                    </p>
                    <template v-if="bankInfo.bankCard == item.account">
                      <van-icon name="success" color="#ee0a24" />
                    </template>
                  </li>
                </ul>
            </div>
            <div class="btns">
                <span class="manual" @click="addBank()">添加银行卡</span>
            </div>
        </div>
    </div>
     <!--协议弹框-->
    <div class="xieyiDialog" v-if="isXy">
        <div class="xieyiDialogCon">
            <span class="xieyiDialogClose" @click="xieyiDialogClose()">x</span>
            <div class="protocolCont" v-html="content"> </div>
        </div>
    </div>
  </div>
</template>
<script>
import { getInitUserReserve,selectBankCardList,getProtocolPreview,doSign} from "../services/proApi";
import { getUserBaseInfo} from "../services/api";
import { Dialog } from 'vant';
import basic from '../utils/basic';
import { encryptRSA, decryptRSA, encryptAES, decryptAES } from "../utils/encryption";
import { queryUserBankCard } from "../services/api";
export default {
  data() {
    return {
      email:'',
      isMask:false,
      detailData:{ },
      content:'',
      isXy:false,
      productId:'',
      reserveId:'',
      ListData:[],
      bankInfo:[],
      checked:'',
    };
  },
  created: function() {
    var _this = this;
    var productId=_this.$route.query.productId;
    var reserveId=_this.$route.query.reserveId;
    _this.productId=productId;
    _this.reserveId=reserveId;
    _this.getInitUserReserve();//
    _this.selectBankCardListData();//获取银行卡
   
  },
   methods: {
  
    async getInitUserReserve(){
      const datas = await getInitUserReserve({reserveId:this.reserveId});
      if (datas.code === 1) {
        this.detailData = datas.data;
      }  
    },
    async getProtocolPreview(type){//协议
      const _this=this;
      if(type==9){
        location.href = 'https://api.liangliangsz.tech/api/product/sign/getSignProtocol?reserveId='+this.reserveId+'&typeId=9'
      }else{
        const datas = await getProtocolPreview({reserveId:this.reserveId,typeId:type});
        if (datas.code === 1) {
          _this.isXy=true
          _this.content=datas.data.content;
        } 
      }
    },
    selectBankCardList(){//选择银行卡
      this.isMask=true;
      this.selectBankCardListData();
    },
    async selectBankCardListData(){//选择银行卡
      const _this=this;
 
      const datas = await queryUserBankCard({});
      if (datas.res_code == 1) {
         let encrypted = datas.data.encrypted; 
          let data = datas.data.msg;
          let aseKey = decryptRSA(encrypted); //RSA解密
          let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
          _this.ListData=res;
           if(this.bankInfo==''){
           _this.bankInfo=res[0];
        }
        console.log("+++++")
          console.log(res[0]); 
      }
    },
    selectB(item){//确定选择的银行卡
      this.isMask=false;
      this.bankInfo=item;
    },
    async doSignFun(){//签约接口
      const _this=this;
      
      if(!basic.isEmail(_this.detailData.email) ){
          _this.$toast('请输入正确的电子邮箱');
          return false
      }
      var accountName=_this.bankInfo.name;
      if (accountName==undefined) {
           _this.$toast('请输入账户名称');
          return false
      }else if (!basic.isName(accountName)) {
          _this.$toast('请输入正确的账户名称');
          return false
      }
      var accountBankName=_this.bankInfo.accountBankName;
      if(!basic.isChinese(accountBankName) ){
          _this.$toast('请输入正确的开户银行名称');
          return false
      }
      var  bankNo=_this.bankInfo.bankCard;
      if(bankNo==""){
           _this.$toast('请输入银行卡号');
          return false
      }else if(!basic.isNum(bankNo)){
           _this.$toast('请输入正确的银行卡号');
          return false
      }
      var userName=_this.detailData.userName;
      if(userName!=accountName){
        Dialog.alert({
          title: '温馨提示',
          confirmButtonText:'我知道了',
          message:'收付款账户名称与认购人姓名不一致，请使用本人的银行卡购买产品',
        })
        .then(() => {
          // on confirm
        })
        return false
      }
      if(_this.checked==""){
         _this.$toast('您还未勾选协议');
        return false
      }
      var sendSata={}
      const datas = await doSign({
        accountName:accountName,
        reserveId:_this.reserveId,
        account:bankNo,
        email:_this.detailData.email,
        accountBankName:accountBankName,
        contractorPhone:_this.bankInfo.userPhone,
      });
      if (datas.code === 1) {
        _this.$router.push({
          path: '/signResult',
          query: { }
        });
        
      } 
    },
    checkXieyi(){
      
    },
    closeBank(){
      this.isMask=false;
    },
     xieyiDialogClose(){
       this.isXy=false
     },
     tips(){
       let msg="若您未注册邮箱，可这样填写：<br> 联通用户：手机号码@wo.cn<br>移动用户：手机号码@139.com<br>电信用户：手机号码@189.cn"
       Dialog.alert({
          title: '温馨提示',
          confirmButtonText:'我知道了',
          message:msg,
        })
          .then(() => {
            // on confirm
          })
     },
     close(name){
        if(name=="email"){
          this.detailData.email=""
        }
     },
     addBank(){
        this.$router.push({
          path: '/realNameBank',
        });
     }
  },
};
</script>

<style scoped lang="scss">
.signDetail{
  p{
    text-align: left;font-size:0.9rem;padding:1.5rem 1rem;color:#356BFF;
    i{font-weight: bold;}
  }
  .signDetailCon{
    li{
       span:last-child{color:#666}
       .van-icon-warning-o{color:rgb(50, 54, 65);font-weight: bold;padding-left:0.1rem;}
       .van-icon-close{color:#666;font-size: 1.1rem;}
    }
  }
  ul{
    background: #fff;padding:0 1rem;
    li{
      height:4rem;
      line-height:4rem;
      font-size:0.9rem;
      display: flex;justify-content: space-between;
      border-bottom:0.01rem solid #e9e9e9;
      input{
        border:none;
        height:2rem;
        line-height:2rem;
        text-align: right;
      }
    }
  }
  .account{
    li{
      display: block;
      span{width: 30%;display: inline-block;}
      input{text-align:left}
      .selectBank{text-align: right;}
    }
  }
  .book{
    margin-top:1rem;
  }
  .xieyi{
    input{
      border:0.01rem solid #333;
      line-height:2rem;
    }
  }
  .tips{color:red;text-align: center;}
}
</style>
<style lang='css'>
.mask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.3);
 }
.mask .contents{
  width: 100%;
  height: 15rem;
  position: fixed;
  bottom: 0;
  font-size: .9rem;
  padding: 0 1rem;
  box-sizing: border-box;
  background: #fff;
  margin: 0 auto;
}
.mask .contents .title{
  display:flex;
  justify-content: space-between;
  padding: .8rem 0;
  border-bottom: 1px solid #eee;
}
.mask .contents .detail{
  height: 10rem;
  overflow-y: scroll;
}
.mask .contents .detail ul{padding: 0;}
.mask .contents .detail li{
  padding: .5rem 0;
  border-bottom: 1px solid #eee;
  display: block;
  height: auto;
  line-height: inherit;
  position: relative;
}
.mask .contents .detail li i {
  position: absolute;    
  right: 5%;
  top: 40%;}
.mask .contents .detail li p{
  padding:0;
  height: 1.2rem;
  color: #333;
}
.mask .contents .btns{
  line-height: 2.5rem;
  text-align: center;
  color: #356BFF;
  font-size: 1rem;
}
.mask .submit{
  text-align: center;
  border-radius: 2rem;
  font-size: 1rem;
  color:#fff;
  margin-top: 2rem;
  background:#E2C151;
  box-shadow:0px 2px 10px 0px rgba(99,99,99,0.32);
}
.xieyiDialog{
	width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.3);
}
.xieyiDialogCon{
    background: #fff;
    height: 100%;
}
.xieyiDialogClose{
	position: absolute;
    right: 0;
    font-size: 18px;
    right: 10px;
    background: #ddd;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    color: #fff;
    line-height: 22px;
    top: 10px;
    z-index: 99;
}
.xieyiDialogCon{
	background: #fff;
}
.xieyiDialogCon .protocolCont{
	height: 100%;
    overflow: auto;
    padding: 10px;
}

</style>
